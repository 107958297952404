import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import moment from 'moment';
import { getHeaders, TableBaseRoot, getAccountType, IO_BUDGET_TYPE_ID } from 'iqm-framework';

import { Filters } from './tableWrapper/Filters';
import { Table, TableComponentInstance } from './tableWrapper/Table';
import { hasNoCustomers } from '../../../utils/errors';
import { AppState } from '../../../store';
import {
  Option,
  StickedOption,
  getColumns,
  getWorkspaceColumnOptions,
  getAdvertiserColumnOptions,
  getExchangesColumnOptions,
  getInsertionOrderColumnOptions,
  flattenOptions,
} from '../../../models/Option';
import { ChangeSortingColumns, filterActions } from '../../../store/filter/actions';
import { TableLevel } from '../../../store/filter/reducers';
import { IndicatorsContainer } from './IndicatorsContainer';
import { SelectedCampaigns } from './tableWrapper/filters/SelectedCampaigns';
import { IQM_API_URL_V3 } from '../../../config';
import { statisticsActions, GetStatisticsTotalStart } from '../../../store/statistics/actions';
import { commonActions } from '../../../store/common/actions';
import {
  tableActions,
  SetTotalItems,
  ClearCampaignAudienceWarnings,
  SetSelectedInsertionOrders,
} from '../../../store/table/actions';
import { toastActions, Open } from '../../../store/toast/actions';
import { NO_CUSTOMER_ASSIGNED } from '../../../constants/tooltips';
import { TableSortedOptionsWrapper } from './tableWrapper/filters/TableSortedOptionsWrapper';
import { SelectedInsertionOrders } from './tableWrapper/filters/SelectedInsertionOrders';
import { SearchWrapperWithIcon } from './tableWrapper/filters/SearchWrapperWithIcon';
import { LambdaResponse } from '../../../models/Response';
import { ViewCreativesDialog } from './tableWrapper/ViewCreativesDialog';
import { ViewCampaignsDialog } from './tableWrapper/ViewCampaignsDialog';
import {
  getImpressionTableSortingCampaigns,
  getImpressionTableSortingIO,
} from '../../../utils/table';

interface Props
  extends ChangeSortingColumns,
    GetStatisticsTotalStart,
    SetTotalItems,
    ClearCampaignAudienceWarnings {
  isAuth: boolean;
  className?: string;
  tableLevel: Option;
  value: StickedOption[];
  options: StickedOption[];
  dataRequest: any;
  transformCSVDownloadRequest: () => {};
  getPrevTotal: () => void;
  getRelatedLambdaData: () => void;
  clearSelected: () => void;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  isCustomersDropdownLoaded: boolean;
  hasNoCustomers: boolean;
  openToast: Open['open'];
  setSelectedTableInsertionOrders: SetSelectedInsertionOrders['setSelectedInsertionOrders'];
  search: string;
}

interface State {
  creativesDialogCampaign: LambdaResponse | null;
  campaignsDialogIO: LambdaResponse | null;
}

class TableWrapperComponent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      creativesDialogCampaign: null,
      campaignsDialogIO: null,
    };
  }

  handleChange = (data) => {
    const mappedColumns = data.map((i) => ({ ...i, active: true }));

    switch (this.props.tableLevel.value) {
      case TableLevel.Workspaces:
        this.props.changeSortingWorkspacesColumns(mappedColumns);
        break;
      case TableLevel.Advertisers:
        this.props.changeSortingAdvertisersColumns(mappedColumns);
        break;
      case TableLevel.Exchanges:
        this.props.changeSortingExchangesColumns(mappedColumns);
        break;
      case TableLevel.InsertionOrders:
        this.props.changeSortingInsertionOrdersColumns(mappedColumns);
        break;
      default:
        this.props.changeSortingColumns(mappedColumns);
        break;
    }
  };

  componentDidUpdate(prevProps) {
    const {
      dataRequest,
      clearSelected,
      tableLevel,
      getRelatedLambdaData,
      setTotalItems,
      clearCampaignAudienceWarnings,
      isCustomersDropdownLoaded,
      getStatisticsTotalStart,
      hasNoCustomers,
      openToast,
      setSelectedTableInsertionOrders,
    } = this.props;

    if (!TableComponentInstance) {
      return;
    }

    if (hasNoCustomers) {
      if (!isEqual(prevProps.dataRequest, dataRequest)) {
        TableComponentInstance.cancelDataRequest();
        TableComponentInstance.setState({
          loadingData: false,
          data: [],
        });
        openToast(NO_CUSTOMER_ASSIGNED);
      }
      return;
    }

    if (isCustomersDropdownLoaded && !prevProps.isCustomersDropdownLoaded) {
      TableComponentInstance.cancelDataRequest();
      TableComponentInstance.setState(
        {
          loadingData: false,
          data: [],
        },
        () => {
          getStatisticsTotalStart();
          TableComponentInstance.getNewData();
        },
      );
      getRelatedLambdaData();
    } else if (
      isEqual(prevProps.tableLevel, tableLevel) &&
      (!isEqual(
        get(prevProps.dataRequest, 'data.sort_by', null),
        get(dataRequest, 'data.sort_by', null),
      ) ||
        !isEqual(
          get(prevProps.dataRequest, 'data.sort_type', null),
          get(dataRequest, 'data.sort_type', null),
        ) ||
        !isEqual(
          get(prevProps.dataRequest, 'data.sortBy', null),
          get(dataRequest, 'data.sortBy', null),
        ) ||
        !isEqual(
          get(prevProps.dataRequest, 'data.sortType', null),
          get(dataRequest, 'data.sortType', null),
        ) ||
        !isEqual(
          get(prevProps.dataRequest, 'data.order', null),
          get(dataRequest, 'data.order', null),
        ))
    ) {
      clearSelected();
      setSelectedTableInsertionOrders([]);
      clearCampaignAudienceWarnings();
      setTotalItems(null);
      TableComponentInstance.cancelDataRequest();
      TableComponentInstance.setState(
        {
          loadingData: false,
          data: [],
        },
        () => {
          getStatisticsTotalStart();
          TableComponentInstance.getNewData();
        },
      );
    } else {
      const isRequestEqual = isEqual(prevProps.dataRequest, dataRequest);
      if (!isRequestEqual) {
        clearSelected();
        setSelectedTableInsertionOrders([]);

        clearCampaignAudienceWarnings();
        setTotalItems(null);
        TableComponentInstance.cancelDataRequest();
        TableComponentInstance.setState(
          {
            loadingData: false,
            data: [],
          },
          () => {
            if (!isRequestEqual) {
              getStatisticsTotalStart();
              TableComponentInstance.getNewData();
            }
            getRelatedLambdaData();
          },
        );
      }
    }
  }

  componentDidMount() {
    const {
      isAuth,
      getRelatedLambdaData,
      isWorkspaceOwnerOrg,
      isCustomersDropdownLoaded,
      getStatisticsTotalStart,
    } = this.props;

    getStatisticsTotalStart();

    if (isAuth && (!isWorkspaceOwnerOrg || isCustomersDropdownLoaded)) {
      getRelatedLambdaData();
    }
  }

  onOpenCreativesDialog = (campaign: LambdaResponse) => {
    this.setState({ creativesDialogCampaign: campaign });
  };

  onCloseCreativesDialog = () => {
    this.setState({ creativesDialogCampaign: null });
  };

  onOpenCampaignsDialog = (insertionOrder: LambdaResponse) => {
    this.setState({ campaignsDialogIO: insertionOrder });
  };

  onCloseCampaignsDialog = () => {
    this.setState({ campaignsDialogIO: null });
  };

  render() {
    const {
      className,
      value,
      options,
      dataRequest,
      tableLevel,
      transformCSVDownloadRequest,
      isPlatformOwnerOrg,
      isWorkspaceOwnerOrg,
      isCustomersDropdownLoaded,
    } = this.props;
    const { creativesDialogCampaign, campaignsDialogIO } = this.state;

    const shouldRequestData =
      (!isPlatformOwnerOrg && !isWorkspaceOwnerOrg) || isCustomersDropdownLoaded;

    // DAS-1268 - We shouldn't be hiding the filters whenever we dont have the data in the table
    // const shouldHideSearch =
    //   shouldRequestData &&
    //   !search &&
    //   (!TableComponentInstance ||
    //     (!TableComponentInstance.state.loading &&
    //       !TableComponentInstance.state.loadingData &&
    //       !get(TableComponentInstance, 'state.data.length')));

    const columnProps = {
      [TableLevel.Campaigns]: {
        allGroupedColumns: options,
        allColumns: options,
      },
      [TableLevel.Advertisers]: {
        allColumns: options,
        allGroupedColumns: getAdvertiserColumnOptions({
          withGroups: true,
          isIqmSuper: isPlatformOwnerOrg,
        }),
      },
      [TableLevel.Workspaces]: {
        allColumns: options,
        allGroupedColumns: getWorkspaceColumnOptions({ withGroups: true }),
      },
      [TableLevel.Exchanges]: {
        allColumns: options,
        allGroupedColumns: getExchangesColumnOptions(true),
      },
      [TableLevel.InsertionOrders]: {
        allColumns: options.reduce(
          (acc: any[], curr: any) =>
            (curr as any).options ? acc.concat(curr.options) : [...acc, curr],
          [],
        ),
        allGroupedColumns: options,
      },
    };

    return (
      <>
        <div className={className}>
          <TableBaseRoot
            selectedColumns={value.filter((v) => v.alwaysEnabled || v.active)}
            onColumnsChanged={this.handleChange}
            dataRequest={
              shouldRequestData
                ? {
                    ...dataRequest,
                    data: {
                      ...(dataRequest ? dataRequest.data : {}),
                    },
                  }
                : undefined
            }
            transformCSVDownloadRequest={
              shouldRequestData ? transformCSVDownloadRequest : undefined
            }
            {...columnProps[tableLevel.value]}
          >
            <IndicatorsContainer className={'dashboard__indicators'} />
            <Filters className={'dashboard__filter'} disableDownloadButtons={!shouldRequestData} />
            <div className="dashboard__elevation">
              <div className={`filter__row _table d-flex my-3 mx-4 justify-content-between`}>
                <div className="d-flex flex-row align-items-center">
                  <SelectedCampaigns />
                  <SelectedInsertionOrders />
                </div>
                {
                  <div className="d-flex w-fit-content">
                    <SearchWrapperWithIcon placeholder="Search by ID, Name" />
                    <TableSortedOptionsWrapper />
                  </div>
                }
              </div>
              <Table
                onOpenCreativesDialog={this.onOpenCreativesDialog}
                onOpenCampaignsDialog={this.onOpenCampaignsDialog}
              />
            </div>
          </TableBaseRoot>
        </div>
        {!!creativesDialogCampaign && (
          <ViewCreativesDialog
            onClose={this.onCloseCreativesDialog}
            campaign={creativesDialogCampaign}
          />
        )}
        {!!campaignsDialogIO && (
          <ViewCampaignsDialog
            onClose={this.onCloseCampaignsDialog}
            insertionOrder={campaignsDialogIO}
          />
        )}
      </>
    );
  }
}

const mapState = (state: AppState) => {
  const {
    filter: {
      tableLevel,
      status,
      dateRange,
      search,
      sortingColumns,
      sortingWorkspacesColumns,
      sortingAdvertisersColumns,
      sortingExchangesColumns,
      sortingInsertionOrdersColumns,
      selectedCreativeTypes,
      timezone,
      advertisersOwIds,
      isCustomersDropdownLoaded,
      selectedInsertionOrders,
      selectedCampaigns,
      ioBudgetTypes,
      campaignTypeIds,
    },
    auth,
    table: { sorting },
  } = state;

  const useWorkspaces = tableLevel.value === TableLevel.Workspaces;
  const useExchanges = tableLevel.value === TableLevel.Exchanges;
  const {
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    isVldEnabled = false,
    hadVldGenerated = false,
  } = auth.userData;

  let query = {
    startDate: dateRange ? `${moment(dateRange.start).valueOf()}` : moment().valueOf(),
    endDate: dateRange ? `${moment(dateRange.end).valueOf()}` : moment().valueOf(),
    timezoneId: timezone ? timezone.id : null,
    campaignStatus: status.value,
    creativeTypeIds: selectedCreativeTypes.map((c) => c.value).join(','),
    searchField: search,
    sortBy: sorting.field,
    order: sorting.direction === 'asc' ? 'ASC' : 'DESC',
    owIds: advertisersOwIds,
  } as any;
  const isImpressionsBudgetSelected =
    ioBudgetTypes?.length === 1 && ioBudgetTypes[0] === IO_BUDGET_TYPE_ID.IMPRESSIONS_BASED;

  let url, visibleSortingColumnOptions, finalVisibleValue, fileName;
  if (tableLevel.value === TableLevel.Campaigns) {
    fileName = 'Campaigns';

    const isAdvertiserOnly = getAccountType() === 'Advertiser';

    const campaignColumns = getColumns({
      isBetaUser: auth.userData.isBetaUser,
      isPlatformOwnerOrg: auth.userData.isPlatformOwnerOrg,
      isWorkspaceOwnerOrg: auth.userData.isWorkspaceOwnerOrg,
      isBidShadingEnabled: isAdvertiserOnly ? auth.userData.isBidShadingEnabled : true,
      isVldEnabled,
      hadVldGenerated,
    });

    const allowedCampaignColValues = flattenOptions(campaignColumns)
      .map((col) => col.value)
      .filter((col) => col);
    finalVisibleValue = sortingColumns.filter((col) =>
      allowedCampaignColValues.includes(col.value),
    );

    visibleSortingColumnOptions = campaignColumns;

    url = `${IQM_API_URL_V3}/das/report/by-campaign`;

    query = {
      ...query,
      token: `${auth.userData.apiToken}`,
      ...(!selectedCampaigns?.length && selectedInsertionOrders?.length
        ? { ioIdsList: selectedInsertionOrders }
        : {}),
      campaignIds: (selectedCampaigns || []).join(','),
      budgetTypeIdList: ioBudgetTypes,
      ...(campaignTypeIds.length ? { campaignTypeIds } : {}),
      sortBy: isImpressionsBudgetSelected
        ? getImpressionTableSortingCampaigns(query.sortBy)
        : query.sortBy,
    };
  } else if (tableLevel.value === TableLevel.InsertionOrders) {
    fileName = 'InsertionOrders';
    visibleSortingColumnOptions = getInsertionOrderColumnOptions({
      isWorkspaceOwnerOrg,
      isPlatformOwnerOrg,
      isVldEnabled,
      hadVldGenerated,
    });
    const allowedCols = flattenOptions(visibleSortingColumnOptions);
    finalVisibleValue = sortingInsertionOrdersColumns.filter((opt) =>
      allowedCols.find((allowedCol) => allowedCol.value === opt.value),
    );

    url = `${IQM_API_URL_V3}/das/report/by-io`;
    query = {
      ...query,
      ...(!selectedCampaigns?.length && selectedInsertionOrders?.length
        ? { ioIdsList: selectedInsertionOrders }
        : {}),
      budgetTypeIdList: ioBudgetTypes,
      sortBy: isImpressionsBudgetSelected
        ? getImpressionTableSortingIO(query.sortBy)
        : query.sortBy,
    };
    delete query.campaignStatus;
    delete query.creativeTypeIds;
  } else if (useWorkspaces) {
    fileName = 'Workspaces';
    visibleSortingColumnOptions = getWorkspaceColumnOptions({
      withGroups: false,
    });
    const allowedCols = flattenOptions(visibleSortingColumnOptions);
    finalVisibleValue = sortingWorkspacesColumns?.filter((opt) =>
      allowedCols.find((allowedCol) => allowedCol.value === opt.value),
    );
    url = `${IQM_API_URL_V3}/das/report/by-workspace`;
    query = {
      ...query,
      ...(campaignTypeIds.length ? { campaignTypeIds } : {}),
    };
  } else if (useExchanges) {
    fileName = 'Exchanges';
    visibleSortingColumnOptions = getExchangesColumnOptions(false);
    finalVisibleValue = sortingExchangesColumns;
    url = `${IQM_API_URL_V3}/das/report/by-exchange`;
    query = {
      ...query,
      campaignIds: (selectedCampaigns || []).join(','),
    };
  } else {
    fileName = 'Advertisers';
    visibleSortingColumnOptions = getAdvertiserColumnOptions({
      withGroups: false,
      isIqmSuper: isPlatformOwnerOrg,
    });
    const allowedCols = flattenOptions(visibleSortingColumnOptions);
    finalVisibleValue = sortingAdvertisersColumns?.filter((opt) =>
      allowedCols.find((allowedCol) => allowedCol.value === opt.value),
    );
    url = `${IQM_API_URL_V3}/das/report/by-customer`;
    query = {
      ...query,
      ...(campaignTypeIds.length ? { campaignTypeIds } : {}),
    };
  }

  const request = {
    url,
    method: 'post',
    data: query,
    headers: getHeaders(auth.userData),
  };

  return {
    tableLevel,
    transformCSVDownloadRequest: () => ({
      ...query,
      url,
      download: true,
      fileType: 'xlsx',
      fileName,
      columns: finalVisibleValue,
      noOfEntries: TableComponentInstance ? TableComponentInstance.state.totalItems : 999999,
    }),
    dataRequest: isCustomersDropdownLoaded ? request : null,
    value: finalVisibleValue,
    options: visibleSortingColumnOptions,
    isAuth: auth.authorized,
    isPlatformOwnerOrg,
    isWorkspaceOwnerOrg,
    isCustomersDropdownLoaded,
    hasNoCustomers: hasNoCustomers(state),
    search,
  };
};

const mapAction = {
  changeSortingColumns: filterActions.changeSortingColumns,
  changeSortingWorkspacesColumns: filterActions.changeSortingWorkspacesColumns,
  changeSortingAdvertisersColumns: filterActions.changeSortingAdvertisersColumns,
  changeSortingExchangesColumns: filterActions.changeSortingExchangesColumns,
  changeSortingInsertionOrdersColumns: filterActions.changeSortingInsertionOrdersColumns,
  getPrevTotal: statisticsActions.getPrevTotal,
  getRelatedLambdaData: commonActions.getRelatedLambdaData,
  clearSelected: tableActions.clearSelectedCampaigns,
  setTotalItems: tableActions.setTotalItems,
  clearCampaignAudienceWarnings: tableActions.clearCampaignAudienceWarnings,
  getStatisticsTotalStart: statisticsActions.getStatisticsTotalStart,
  openToast: toastActions.open,
  setSelectedTableInsertionOrders: tableActions.setSelectedInsertionOrders,
};
export const TableWrapper = connect(mapState, mapAction)(TableWrapperComponent);
