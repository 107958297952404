export const getImpressionTableSortingCampaigns = (sortingField: string) => {
  const dollarBasedToImpressionSortingKeys: { [key: string]: string } = {
    budgetDay: 'dailyImpressions',
    budgetTotal: 'targetImpression',
  };

  return dollarBasedToImpressionSortingKeys[sortingField] || sortingField;
};

export const getImpressionTableSortingIO = (sortingField: string) => {
  const dollarBasedToImpressionSortingKeys: { [key: string]: string } = {
    ioTotalBudget: 'ioTotalImpressions',
  };

  return dollarBasedToImpressionSortingKeys[sortingField] || sortingField;
};
