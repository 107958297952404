let callbackP: Promise<(params: Params) => void>;

interface Params {
  token: string;
  clientId: string;
  developerKey: string;
  callbackFunction: Parameters<google.picker.PickerBuilder['setCallback']>[0];
  title?: string;
  viewId?: keyof typeof window.google.picker.ViewId;
  viewMimeTypes?: string;
  setSelectFolderEnabled?: boolean;
}

const usePicker = () => {
  if (!callbackP) {
    callbackP = (async () => {
      await new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.async = true;
        script.addEventListener('load', () => resolve());
        script.addEventListener('error', () => reject());
        document.body.appendChild(script);
      });
      await new Promise<void>((resolve, reject) => {
        window.gapi.load('picker', { callback: resolve, onerror: reject });
      });
      return ({
        developerKey,
        token,
        callbackFunction,
        title,
        viewId = 'DOCS',
        viewMimeTypes,
        setSelectFolderEnabled,
      }: Params) => {
        const picker = new window.google.picker.PickerBuilder()
          .setOAuthToken(token)
          .setDeveloperKey(developerKey)
          // eslint-disable-next-line
          .setCallback((...args) => (console.log('cb', args), callbackFunction(...args)));
        if (title) picker.setTitle(title);

        const view = new window.google.picker.DocsView(window.google.picker.ViewId[viewId]);
        if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
        if (setSelectFolderEnabled) view.setSelectFolderEnabled(setSelectFolderEnabled);

        picker.addView(view);

        picker.enableFeature(window.google.picker.Feature.SUPPORT_DRIVES);
        picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);

        picker.build().setVisible(true);
      };
    })();
  }
  return callbackP;
};

export default usePicker;
