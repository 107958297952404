import { logDataDogAction } from 'iqm-framework';
import { SERVICE } from '../constants';

export const Report = {
  changeGroupBy: (label: string) => {
    logDataDogAction('Report > Change Group By', {
      action: `${SERVICE}/sidebar/report/groupBy`,
      info: {
        label,
      },
    });
  },
  changeMetric: (label: string) => {
    logDataDogAction('Report > Change Metric', {
      action: `${SERVICE}/sidebar/report/metric`,
      info: {
        label,
      },
    });
  },
};
