import { logDataDogAction } from 'iqm-framework';
import { SERVICE } from '../constants';

export const Graph = {
  changeMetric1: (label: string) => {
    logDataDogAction('Graph > Change Metric 1', {
      action: `${SERVICE}/sidebar/graph/metric1`,
      info: {
        label,
      },
    });
  },
  changeMetric2: (label: string) => {
    logDataDogAction('Graph > Change Metric 2', {
      action: `${SERVICE}/sidebar/graph/metric2`,
      info: {
        label,
      },
    });
  },
};
