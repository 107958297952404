import React from 'react';
import { Select } from 'factor';
import isEqual from 'lodash/isEqual';
import {
  getMetricOptions,
  AggregatedMetricOption,
  ReportingAvialableMetrics,
} from '../../../../models/Graph';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';
import { Option } from '../../../../models/Option';
import { GraphsNames } from '../../../../models/GraphsNames';
import { filterActions } from '../../../../store/filter/actions';
import { toastActions, Open } from '../../../../store/toast/actions';
import { hasNoCustomers } from '../../../../utils/errors';
import { NO_CUSTOMER_ASSIGNED } from '../../../../constants/tooltips';

interface OuterProps {
  stateKey: GraphsNames;
  excludingOption?: Option;
  className?: string;
  legendColor?: string;
  tooltipParams?: {
    position?: string;
  };
  onUserSelect?: (selectedOption: Option, prevOption: Option) => void;
}
interface Props extends OuterProps {
  value: Option;
  changeGraphSelectValue: (value: Option, key: GraphsNames) => void;
  isPlatformOwnerOrg: boolean;
  isWorkspaceOwnerOrg: boolean;
  tableLevel: Option;
  openToast: Open['open'];
  hasNoCustomers: boolean;
}

interface State {
  options: AggregatedMetricOption[];
}

class GraphSelectWrapperComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { tableLevel, isPlatformOwnerOrg, isWorkspaceOwnerOrg } = this.props;
    this.state = { options: getMetricOptions(tableLevel, isPlatformOwnerOrg, isWorkspaceOwnerOrg) };
  }

  handleChange = (value) => {
    if (this.props.hasNoCustomers) {
      this.props.openToast(NO_CUSTOMER_ASSIGNED);
    }
    const selectedValueId = ReportingAvialableMetrics[value.value] || value.value;
    this.props.changeGraphSelectValue(
      {
        ...value,
        value: selectedValueId,
      },
      this.props.stateKey,
    );
  };

  componentDidUpdate(prevProps: Props) {
    const { isPlatformOwnerOrg, isWorkspaceOwnerOrg, tableLevel } = this.props;
    if (
      !isEqual(tableLevel, prevProps.tableLevel) ||
      isPlatformOwnerOrg !== prevProps.isPlatformOwnerOrg ||
      isWorkspaceOwnerOrg !== prevProps.isWorkspaceOwnerOrg
    ) {
      this.setState(
        { options: getMetricOptions(tableLevel, isPlatformOwnerOrg, isWorkspaceOwnerOrg) },
        () => {
          const updateValue = !this.state.options.some((optionGroup) =>
            (optionGroup.options || []).some(
              (opt) =>
                (ReportingAvialableMetrics[opt.value] || opt.value) === this.props.value.value,
            ),
          );
          if (updateValue) {
            this.handleChange(this.state.options[0].options[0]);
          }
        },
      );
    }
  }

  render() {
    const { value, className = '', legendColor = '', tooltipParams = {} } = this.props;

    return (
      <div className={`${className}`}>
        <Select
          className={`_with-legend _${legendColor}`}
          onChange={(selectedOption) => {
            const selectedId =
              ReportingAvialableMetrics[selectedOption.value] || selectedOption.value;
            this.props.onUserSelect?.(
              {
                ...selectedOption,
                value: selectedId,
              },
              value,
            );
            this.handleChange(selectedOption);
          }}
          value={value}
          options={this.state.options}
          placeholder="Metric"
          label="Metric"
          tooltipParams={{
            label:
              'Metric or Key Performance Indicator (KPI) is the measured value that you can use to understand the effectiveness of your campaigns',
            position: tooltipParams.position || 'bottom',
            auto: false,
            labelMaxWidth: 304,
          }}
        />
      </div>
    );
  }
}

const mapState = (state: AppState, props: OuterProps) => {
  return {
    value: state.filter[props.stateKey],
    isPlatformOwnerOrg: state.auth.userData.isPlatformOwnerOrg,
    isWorkspaceOwnerOrg: state.auth.userData.isWorkspaceOwnerOrg,
    tableLevel: state.filter.tableLevel,
    hasNoCustomers: hasNoCustomers(state),
  };
};

const mapActions = {
  changeGraphSelectValue: filterActions.changeGraphSelectValue,
  openToast: toastActions.open,
};

export const GraphSelectWrapper = connect(mapState, mapActions)(GraphSelectWrapperComponent);
