import { getInstance } from './index';

export function getCreativeTypes(): Promise<any> {
  return getInstance()
    .get('/v3/crt/master/static/creative-types')
    .then((response) => response.data.data.creativeTypeList)
    .catch((err) => {
      console.log('err', err);
      return Promise.reject(err);
    });
}
