import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TableComponent, TableBaseRoot, getHeaders } from 'iqm-framework';
import capitalize from 'lodash/capitalize';
import camelCase from 'lodash/camelCase';
import moment from 'moment';

import { LoadingStatus } from '../../../../../models/LoadingStatus';
import { LambdaResponse } from '../../../../../models/Response';
import { CreativeBasic as Creative } from '../../../../../models/Creative';
import { CreativeStatusToNameMapping, statusIconMap } from '../../../../../models/Campaign';
import { TableSortingParams } from '../../../../../models/Table';
import { Option } from '../../../../../models/Option';
import { User } from '../../../../../models/User';
import {
  CreativeTypeIconMapper,
  VIDEO_CREATIVE_ID,
  AUDIO_CREATIVE_ID,
  creativeTypeViewByCreativeTypeId,
  creativeStatusOptions,
  IMAGE_CREATIVE_ID,
  HTML_CREATIVE_ID,
  NATIVE_CREATIVE_ID,
} from '../../../../../constants/creatives';
import { WithErrorPlaceholderImage } from '../../../../../components/WithErrorPlaceholderImage';
import { IQM_API_URL_V3 } from '../../../../../config';
import { AppState } from '../../../../../store';
import { SearchField } from '../../../../../components/SearchField';
import { StatusFilter } from './StatusFilter';
import { TableDialogWrapper } from '../TableDialogWrapper';
import { IconTextCell } from '../cellTypes/IconTextCell';
import styles from './styles.module.scss';

interface StateProps {
  userData: User;
}

interface OwnProps {
  onClose: () => void;
  campaign: LambdaResponse;
}

interface Props extends OwnProps, StateProps {}

const DEFAULT_SORTING = { direction: 'desc' as 'desc', field: 'creativeId' } as TableSortingParams;

let TableComponentInstance;

export const ViewCreativesDialogComponent = (props: Props) => {
  const { onClose, campaign, userData } = props;
  const { campaignName, creativeType, creativesCount } = campaign;
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.PENDING);
  const [searchField, setSearchField] = useState('');
  const [sorting, setSorting] = useState<TableSortingParams>(DEFAULT_SORTING);
  const [selectedStatus, setSelectedStatus] = useState<Option>(creativeStatusOptions[0]);

  const creativeIconName = CreativeTypeIconMapper[camelCase(creativeType)];

  const tableConfig = useMemo(
    () => ({
      header: {
        creativeId: {
          label: 'ID',
          className: 'w-80-80',
          sortingKey: 'creativeId',
          draggable: false,
        },
        creativeThumbnailSource: {
          label: 'Preview',
          className: 'w-135-135',
          draggable: false,
        },
        creativeName: {
          label: 'Creative Name',
          sortingKey: 'creativeName',
          className: 'w-250-650',
          draggable: false,
        },
        creativeStatusId: {
          label: 'Status',
          className: 'w-120-120',
          draggable: false,
        },
        externalCreativeId: {
          label: '3rd Party ID',
          sortingKey: 'externalCreativeId',
          className: 'w-120-120',
          draggable: false,
        },
        clickUrl: {
          label: 'Click URL',
          className: 'w-150-200',
          draggable: false,
        },
        ...((creativeType || '').toLowerCase() === 'image'
          ? {
              pixelUrls: {
                label: 'Pixel URL',
                className: 'w-150-200',
                draggable: false,
              },
            }
          : {}),
        createdAt: {
          label: 'Created On',
          sortingKey: 'createdAt',
          className: 'w-135-135',
          draggable: false,
        },
        size: {
          label: 'Size',
          className: 'w-135-135',
          draggable: false,
        },
      },
      body: {
        creativeId: { key: 'creativeId', className: 'w-80-80' },
        creativeThumbnailSource: {
          className: `w-135-135 ${styles.previewImageWrapper}`,
          key: (creative: Creative) => {
            let placeholderIcon;
            switch (creative.platformCreativeTypeId) {
              case AUDIO_CREATIVE_ID:
                placeholderIcon = 'CreativeAudioDefaultThumbnailCardView';
                break;
              case VIDEO_CREATIVE_ID:
                placeholderIcon = 'CreativeVideoDefaultThumbnailCardView';
                break;
              case IMAGE_CREATIVE_ID:
                placeholderIcon = 'CreativeImageDefaultThumbnailCardView';
                break;
              case HTML_CREATIVE_ID:
                placeholderIcon = 'CreativeHTMLDefaultThumbnailCardView';
                break;
              case NATIVE_CREATIVE_ID:
                placeholderIcon = 'CreativeNativeDefaultThumbnailCardView';
                break;
              default:
                placeholderIcon = 'NoImage';
            }

            return (
              <WithErrorPlaceholderImage
                src={creative.creativeCardSource}
                errorIconName={placeholderIcon}
                previewFlag={1}
              />
            );
          },
        },
        creativeName: {
          key: (data: Creative) => {
            const { iconName } =
              creativeTypeViewByCreativeTypeId[data.platformCreativeTypeId] || {};
            return <IconTextCell text={data.creativeName} iconName={iconName} />;
          },
          className: 'w-250-650',
        },
        creativeStatusId: {
          key: (data: Creative) => {
            const { creativeStatusId = 0 } = data;
            const statusName = CreativeStatusToNameMapping[creativeStatusId];
            const iconName = statusIconMap[statusName];

            return (
              <IconTextCell
                text={capitalize(statusName)}
                iconClassName={styles.statusIcon}
                iconName={iconName}
              />
            );
          },
          className: 'w-120-120',
        },
        externalCreativeId: {
          key: (data: Creative) => {
            return data.externalCreativeId || '–';
          },
          className: 'w-120-120',
        },
        clickUrl: {
          key: (data: Creative) => {
            return data.clickUrl || '—';
          },
          className: 'w-150-200',
        },
        ...((creativeType || '').toLowerCase() === 'image'
          ? {
              pixelUrls: {
                key: (creative: Creative) =>
                  creative.pixelUrls && creative.pixelUrls.length
                    ? creative.pixelUrls.join(', ')
                    : '—',
                className: `w-150-200 ${styles.pixelUrl}`,
              },
            }
          : {}),
        createdAt: {
          key: (data: Creative) => moment(data.createdAt).format('MM/DD/YYYY'),
          className: 'w-135-135 _right',
        },
        size: {
          className: 'w-135-135 _right',
          key: (data: Creative): string => {
            const { platformCreativeTypeId, duration, creativeHeight, creativeWidth } = data;
            switch (platformCreativeTypeId) {
              case VIDEO_CREATIVE_ID:
              case AUDIO_CREATIVE_ID:
                return `${duration}s`;
              case NATIVE_CREATIVE_ID:
                return duration ? `${duration}s` : `${creativeWidth}x${creativeHeight}`;
              default:
                return `${creativeWidth}x${creativeHeight}`;
            }
          },
        },
      },
    }),
    [creativeType],
  );

  const columns = useMemo(
    () => Object.keys(tableConfig.header).map((item) => ({ value: item, alwaysEnabled: true })),
    [tableConfig],
  );

  useEffect(() => {
    if (TableComponentInstance) {
      TableComponentInstance.cancelDataRequest();
      TableComponentInstance.setState(
        {
          loadingData: false,
          data: [],
        },
        () => {
          TableComponentInstance.getNewData();
        },
      );
    }
  }, [searchField, selectedStatus]);

  const dataRequest = useMemo(() => {
    const headers = getHeaders(userData);
    const { isPlatformOwnerOrg, isWorkspaceOwnerOrg } = userData || {};

    const request = {
      url: `${IQM_API_URL_V3}/crt/creatives/list`,
      method: 'post',
      data: {
        campaignIds: [campaign.campaignId],
        owIds:
          (isPlatformOwnerOrg || isWorkspaceOwnerOrg) && campaign.owId
            ? [campaign.owId]
            : undefined,
        searchField,
        creativeStatusIds: selectedStatus.value ? [selectedStatus.value] : undefined,
        sortBy: sorting ? `${sorting.direction === 'desc' ? '-' : '+'}${sorting.field}` : undefined,
      },
      headers,
    };
    return request;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, userData, tableConfig, searchField, selectedStatus.value, sorting]);

  const transformRequestParams = useCallback((apiParams) => {
    const params = { ...apiParams };
    params.pageNo = params.pgno;
    params.noOfEntries = params.no_of_entries;

    delete params.sort_by;
    delete params.sort_type;
    delete params.draw;
    delete params.pgno;
    delete params.no_of_entries;

    return params;
  }, []);

  const updateSorting = useCallback(
    ({ sorting: sortingParams }: { sorting: TableSortingParams }) => {
      setSorting(sortingParams);
    },
    [],
  );

  const onFetchDataError = useCallback(() => {
    setLoading(LoadingStatus.ERROR);
  }, []);

  const setRef = useCallback((ref) => {
    TableComponentInstance = ref;
  }, []);

  return (
    <TableDialogWrapper
      titleIcon={creativeIconName}
      title={campaignName || ''}
      subtitle={`(${creativesCount} Creative${!creativesCount || creativesCount > 1 ? 's' : ''})`}
      loading={loading}
      onClose={onClose}
    >
      <>
        <div className={styles.filters}>
          <StatusFilter selected={selectedStatus} onSelect={setSelectedStatus} />
          <div className={styles.searchField}>
            <SearchField
              search={searchField}
              updateSearch={setSearchField}
              placeholder="Search by ID, Name, 3rd Party ID"
            />
          </div>
        </div>
        <TableBaseRoot
          selectedColumns={columns as any[]}
          allColumns={columns as any[]}
          onColumnsChanged={() => {
            /* noop */
          }}
          dataRequest={dataRequest}
        >
          <TableComponent
            headerMapping={tableConfig.header}
            bodyMapping={tableConfig.body}
            offsetTop={0}
            checkbox={false}
            checkboxInHeader={false}
            emptyTableLabel="No Creatives Found"
            dataPath="data.filteredList"
            countPath="data.filteredRecords"
            idField="creativeId"
            skeleton={{
              columns: Object.values(tableConfig.header).length,
              rows: 6,
            }}
            tableParams={{
              tbodyRowHeight: 48,
              theadRowHeight: 48,
              preventNavigationByScroll: true,
              fixedXScroller: true,
              rowKeyExtractor: (row) => row.id,
              onChange: updateSorting,
              sorting,
              tableMaxHeight: '400px',
              fixedHeader: true,
              windowFreeResizeEvent: true,
            }}
            transformRequestParams={transformRequestParams}
            defaultSorting={sorting}
            onFetchDataError={onFetchDataError}
            innerRef={setRef}
          />
        </TableBaseRoot>
      </>
    </TableDialogWrapper>
  );
};

const mapState = (state: AppState) => ({
  userData: state.auth.userData,
});

export const ViewCreativesDialog = connect<any, any, any, any>(mapState)(
  ViewCreativesDialogComponent,
);
