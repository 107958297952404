import React, { useEffect, useState } from 'react';
import { Icon } from 'factor';

import styles from './styles.module.scss';

type WithErrorPlaceholderImageProps = {
  src: string;
  className?: string;
  errorIconName?: string;
  errorIconClassName?: string;
  loadCreativePreview?: () => void;
  previewFlag: 0 | 1;
};

function checkIfImageExists(url: string, callback: Function) {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    callback(true);
  };
  img.onerror = () => {
    callback(false);
  };
}

export const WithErrorPlaceholderImage = (props: WithErrorPlaceholderImageProps) => {
  const [handleLoadingError, setHandleLoadingError] = useState(false);
  const [timer, setTimer] = useState<number>(0);
  const [hideImage, setHideImage] = useState<boolean>(false);
  const [localImgSrc, setLocalImgSrc] = useState<string>('');

  const imageRef = React.useRef<HTMLImageElement>();

  const onImageLoad = () => {
    if (props.previewFlag === 1) {
      checkIfImageExists(props.src, (exists: boolean) => {
        if (exists) {
          return setLocalImgSrc(props.src);
        }
        setHideImage(true);
      });
    }
  };

  useEffect(() => {
    if (timer < 3 && props.previewFlag === 0) {
      let timer1 = setTimeout(
        () =>
          checkIfImageExists(props.src, (exists: boolean) => {
            if (exists) {
              setLocalImgSrc(props.src);
              setHideImage(false);
            } else {
              setTimeout(() => {
                onImageLoad();
                setTimer((curr) => curr + 1);
              }, 3000);
            }
            setHandleLoadingError(!exists);
          }),
        3000,
      );
      return () => {
        clearTimeout(timer1);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    onImageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props.previewFlag !== undefined && props.previewFlag !== 0 && !hideImage && localImgSrc ? (
        <img
          // @ts-ignore
          ref={imageRef}
          className={`${styles.creativeImage || ''} ${handleLoadingError ? styles.hidden : ''}`}
          onError={(e) => {
            // @ts-ignore
            e.target = null;
            setHideImage(true);
          }}
          src={localImgSrc}
        />
      ) : (
        <Icon name={props.errorIconName || 'NoImage'} className={styles.errorIcon} />
      )}
    </>
  );
};
