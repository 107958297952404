import { Campaigns } from './Campaigns';
import { InsertionOrders } from './InsertionOrders';
import { App } from './App';
import { Report } from './Report';
import { Graph } from './Graph';

export const DataDogLogger = {
  Campaigns,
  InsertionOrders,
  App,
  Report,
  Graph,
};
